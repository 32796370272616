import React, { useEffect } from "react"
import * as styles from "../paymentTypes.module.scss"
import SepaIcon from "./sepa-icon.svg"
import ChckboxIcon from "../check-icon.svg"
import * as formStyles from "../../../../scss/form.module.scss"
import CheckIcon from "../check-icon.svg"
import { IbanElement, useElements } from '@stripe/react-stripe-js';

const Sepa = ({ active, paymentData, handleChange, hasError }) => {

    const elements = useElements();

    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: "#32325d",
                fontFamily:
                    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#aab7c4"
                },
                ":-webkit-autofill": {
                    color: "#32325d"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
                ":-webkit-autofill": {
                    color: "#fa755a"
                }
            }
        },
        classes: {
            // base: styles.input,
            complete: styles.input,
            empty: styles.input,
            focus: styles.input,
            invalid: styles.input,
            webkitAutofill: styles.input,
        },
        supportedCountries: ['SEPA'],
    };


    useEffect(() => {

        if (!elements) {
            console.log("NO ELEMENTS")
            return;
        }

        const element = elements.getElement(IbanElement);

        if (element !== null) {
            console.log('EL', element)
            element.on('change', function (event) {

                handleChange('sepaIban', event)

            });
        }


    })


    return (
        <div>
            <div className={`${styles.item} ${active && styles.active}`}>
                <div className={styles.btn}>
                    <SepaIcon />
                    SEPA-Lastschrift
                    {active && <ChckboxIcon className={styles.activeIcon} />}
                </div>
                {active && (
                    <>
                        <div className={`${formStyles.inputGroup} ${hasError ? formStyles.inputError : ''}`}>
                            <p>IBAN *</p>

                            <IbanElement
                                options={CARD_ELEMENT_OPTIONS}
                            />

                        </div>
                        <p>Information zum Lastschriftmandat.</p>
                        <p>
                            Durch die Bereitstellung deiner IBAN und Bestätigung dieser
                            Bestellung, autorisierst du und unseren Zahlungsdienstleister
                            Stripe eine Anweisung an deine Bank zu senden, um dein Konto zu
                            belasten. Du hast Anspruch auf eine Rückerstattung von deiner Bank
                            unter den Bedingungen und Konditionen deiner Vereinbarung mit
                            deiner Bank. Eine Rückerstattung muss innerhalb von 8 Wochen ab
                            dem Tag, an dem dein Konto belastet wurde, in Anspruch genommen
                            werden.
                        </p>

                        {/* <div className={`${formStyles.checkboxGroup}`}>
                            <div className={formStyles.checkbox}>
                                <input id="checkbox_12" type="checkbox" />
                                <label htmlFor="checkbox_12">
                                    <CheckIcon />
                                </label>
                            </div>
                            <p>
                                Zahlungsinformationen für zukünftige Einkäufe in meinem Konto
                                speichern.
              </p>
                        </div> */}
                    </>
                )}
            </div>
        </div>
    )
}

export default Sepa
