import React from "react"
import * as styles from "../paymentTypes.module.scss"
import SofortIcon from "./sofort-icon.svg"
import ChckboxIcon from "../check-icon.svg"
const Sofort = ({ active }) => {
  return (
    <>
      <div className={`${styles.item} ${active && styles.active}`}>
        <div className={styles.btn}>
          <SofortIcon />
          SOFORT
          {active && <ChckboxIcon className={styles.activeIcon} />}
        </div>
        {active && <p>Sie werden zur Zahlung zu SOFORT weitergeleitet.</p>}
      </div>
    </>
  )
}

export default Sofort
