import React, { useEffect, useRef, useState } from "react"
import * as formStyles from "../../scss/form.module.scss"
import * as styles from "./checkout.module.scss"
import * as btnStyles from "../buttons/buttons.module.scss"

import PaymentTypes from "./paymentTypes"
import CheckIcon from "./paymentTypes/check-icon.svg"
import { changeCheckoutStep } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { navigate } from "@reach/router";
import { withPrefix } from "gatsby";
import LoadingSpinner from '../loadingSpinner'
const ThirdStep = () => {

    const stripePromise = loadStripe('pk_live_51IxTG8KOpfx6FT09a5UlORP8Tbn7Ake9IMop0Xw9dZpz1izLtbSyS9MEolA3Jn1z17HnPkwHXtARv6ye4RwLireQ00xAImUL04');

    const paymentTypesRef = useRef();

    const [agbAccepted, setAgbAccepted] = useState(false)
    const [showError, setShowError] = useState(false)
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    dispatch(changeCheckoutStep({ currentStep: 3 }))

    const handleCheckout = (e) => {
        e.preventDefault();

        setShowError(false);

        if (!agbAccepted) {
            setShowError(true);
            return false;
        }

        setLoading(true);
        paymentTypesRef.current.processStripePayment();

    }

    return (
        <form className={`${formStyles.form} ${loading ? styles.loading : null}`} onSubmit={handleCheckout}>
            <div className={styles.title} style={{ marginBottom: "20px" }}>
                Zahlung
            </div>
            <Elements stripe={stripePromise}>
                <PaymentTypes
                    ref={paymentTypesRef}
                    setLoading={setLoading}
                />
            </Elements>
            <p style={{ fontSize: "12px" }}>Mit dem Kauf dieses Produkts, wird ein persönliches Konto erstellt und der Kurs sofort für dich freigeschaltet.</p>


            <label className={`${formStyles.checkboxGroup} ${!agbAccepted ? formStyles.inputError : null}`} onClick={() => {
            }} htmlFor="checkbox_12">

                <div className={formStyles.checkbox}>
                    <input onChange={() => {
                        setAgbAccepted(!agbAccepted)
                        setShowError(false)
                    }} style={{ display: 'inline-block' }} id="checkbox_12" type="checkbox" />
                    <CheckIcon />
                </div>
                <p>
                    Ich habe die <a href="#">Geschäftsbedingungen</a> gelesen und stimme ihnen zu. *
                </p>
            </label>
            <div className={formStyles.twoItemsGroup}>
                <button onClick={() => { navigate(withPrefix("/checkout/2")) }} className={`${btnStyles.button} ${btnStyles.buttonBorder}`}>
                    Zurück
                </button>
                <button
                    onClick={handleCheckout}
                    className={`${btnStyles.button} ${btnStyles.buttonBorder} ${!agbAccepted ? btnStyles.disabled : null}`}
                    style={loading ? { paddingLeft: 0 } : {}}
                >
                    Kauf abschließen
                    {
                        loading && <LoadingSpinner
                            style={{ width: 20, height: 20, float: 'left', position: 'relative', marginRight: 30 }}
                        />
                    }
                </button>
            </div>
        </form >
    )
}

export default ThirdStep
