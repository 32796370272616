import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react"
import Inputmask from "inputmask"
import CardIcons from "./card-icons.svg"
import CardIcon from "./card-icon.svg"
import CheckIcon from "../check-icon.svg"
import * as styles from "../paymentTypes.module.scss"
import * as formStyles from "../../../../scss/form.module.scss"

import { CardNumberElement, CardExpiryElement, CardCvcElement, useElements } from '@stripe/react-stripe-js';

const Card = ({ active, handleChange, hasError }) => {

    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: 'inherit',
                fontSmoothing: "antialiased",
                fontSize: "18px",
                "::placeholder": {
                    color: "#aab7c4",
                },
            },
            invalid: {
                color: "#FA755A",
                iconColor: "#FA755A",
            },
        },
        classes: {
            // base: styles.input,
            complete: styles.input,
            empty: styles.input,
            focus: styles.input,
            invalid: styles.input,
            webkitAutofill: styles.input,
        },
        hidePostalCode: true
    };

    const elements = useElements();

    useEffect(() => {

        if (!elements) {
            console.log("NO ELEMENTS")
            return;
        }

        const elementNumber = elements.getElement(CardNumberElement);
        const elementExpiry = elements.getElement(CardExpiryElement);
        const elementCvC = elements.getElement(CardCvcElement);

        if (elementNumber !== null) {

            elementNumber.on('change', function (event) {

                handleChange('creditCardNumber', event)

            });

            elementExpiry.on('change', function (event) {

                handleChange('creditCardExpiry', event)

            });

            elementCvC.on('change', function (event) {

                handleChange('creditCardCVC', event)

            });
        }


    })

    return (
        <div
            className={`${styles.item} ${active && styles.active}`}
            onClick={() => { }}
        >
            <div className={styles.btn}>
                <CardIcon />
                Kreditkarte
                {active && <CheckIcon className={styles.activeIcon} />}
            </div>
            {active && (
                <div>
                    <p
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        Per Kreditkarte bezahlen.
                        <CardIcons />
                    </p>
                    <div className={`${formStyles.inputGroup} ${hasError ? formStyles.inputError : ''}`}>
                        <br />Kartennummer *<br />
                        <div className={formStyles.inputRightIcon}>
                            <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
                            <CardIcon style={{ width: 25 }} />
                        </div>
                    </div>
                    <div className={formStyles.twoItemsRow}>
                        <div className={`${formStyles.inputGroup} ${hasError ? formStyles.inputError : ''}`}>
                            <br />Gültig bis *<br />
                            <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
                            {/* <input
                                type="text"
                                value={paymentData.creditCardExpiry}
                                onChange={val => handleChange('creditCardExpiry', val)}
                                placeholder="MM / JJ"
                                className={formStyles.input}
                            /> */}
                        </div>
                        <div className={`${formStyles.inputGroup} ${hasError ? formStyles.inputError : ''}`}>
                            <br />Sicherheitscode (CVC) *<br />
                            <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
                            {/* <input
                                type="text"
                                value={paymentData.creditCardCVC}
                                onChange={val => handleChange('creditCardCVC', val)}
                                placeholder="Prüfziffer"
                                className={formStyles.input}
                            /> */}
                        </div>

                    </div>

                    {/* <div className={`${formStyles.checkboxGroup}`}>
                        <div className={formStyles.checkbox}>
                            <input id="checkbox_12" type="checkbox" />
                            <label htmlFor="checkbox_12">
                                <CheckIcon />
                            </label>
                        </div>
                        <p>
                            Zahlungsinformationen für zukünftige Einkäufe in meinem Konto
                            speichern.
            </p>
                    </div> */}
                </div>
            )}
        </div>
    )
}

export default Card
