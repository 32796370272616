import React, { forwardRef, useImperativeHandle, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import { changeCheckoutStep, storeFormDataAction } from "../../../store/actions";

import * as styles from "./paymentTypes.module.scss"

import Card from "./card"
import Paypal from "./paypal"
import Sepa from "./sepa"
import Sofort from "./sofort"

import * as formStyles from "../../../scss/form.module.scss"

import { useStripe, useElements } from '@stripe/react-stripe-js';
import { CardNumberElement, CardExpiryElement, CardCvcElement, IbanElement } from '@stripe/react-stripe-js';
import axios from "axios";
import { navigate } from "@reach/router";

const PaymentTypes = forwardRef((props, ref) => {

    const dispatch = useDispatch();
    const paymentType = useSelector(state => state.checkout.formData.paymentType)

    const stripe = useStripe();
    const elements = useElements();

    const formData = useSelector(state => state.checkout.formData)

    const [hasError, setHasError] = useState(false);

    const changePaymentTypeHandler = (type) => {
        dispatch(storeFormDataAction({ paymentType: type }))
        dispatch(changeCheckoutStep({ currentStep: 3 }))
    }

    const [paymentData, setPaymentData] = useState({
        creditCardNumber: false,
        creditCardExpiry: false,
        creditCardCVC: false,
        sepaIban: false
    });

    const handleChange = (key, event) => {

        setPaymentData(prevState => ({
            ...prevState,
            [key]: event.complete
        }))

    }

    useImperativeHandle(ref, () => ({

        processStripePayment() {

            if ((!stripe || !elements) && paymentType !== 'paypal') {
                return;
            }
            setHasError(false)

            let cardNumber = null;
            let cardExpiry = null;
            let cardCVC = null;
            let ibanElement = null;

            let valid = true;

            switch (paymentType) {
                case 'card':

                    if (!paymentData.creditCardNumber || !paymentData.creditCardExpiry || !paymentData.creditCardCVC) {
                        valid = false;
                    }

                    cardNumber = elements.getElement(CardNumberElement);
                    cardExpiry = elements.getElement(CardExpiryElement);
                    cardCVC = elements.getElement(CardCvcElement);

                    break;


                case 'sepa':

                    if (!paymentData.sepaIban) {
                        valid = false;
                    }

                    ibanElement = elements.getElement(IbanElement);

                    break;
            }

            if (!valid) {
                props.setLoading(false)
                setHasError(true)
                return false;
            }
            axios.post('https://detoxme.at/wp/wp-json/siegfried/v1/shop/order/create', {
                items: [
                    {
                        product_id: 740,
                        quantity: 1
                    }
                ],
                payment_method: paymentType,
                buyer: {
                    login: {
                        email: formData.email,
                        password: formData.password,
                    },
                    billing: {
                        first_name: formData.firstName,
                        last_name: formData.lastName,
                        email: formData.email,
                        phone: formData.phone,
                        address_1: formData.address,
                        address_2: "",
                        city: formData.city,
                        postcode: formData.postcode,
                        country: "AT"
                    }
                }
            })
                .then(function (response) {

                    switch (paymentType) {
                        case 'card':

                            stripe.confirmCardPayment(
                                response.data.client_secret,
                                {
                                    payment_method: { card: cardNumber }
                                }
                            ).then(function (result) {

                                if (result.error) {
                                    navigate('/checkout/error/')
                                } else {
                                    navigate('/checkout/success/')
                                }

                            })

                            break;

                        case 'sofort':

                            stripe
                                .confirmSofortPayment(response.data.client_secret, {
                                    payment_method: {
                                        sofort: {
                                            country: 'AT'
                                        }
                                    },
                                    // Return URL where the customer should be redirected after the authorization.
                                    return_url: 'https://detoxme.at/checkout/waiting',
                                })
                                .then(function (result) {
                                    if (result.error) {
                                        console.log(result.error)
                                    } else {
                                        console.log(result)
                                    }
                                });
                            break;

                        case 'sepa':

                            stripe
                                .confirmSepaDebitPayment(response.data.client_secret, {
                                    payment_method: {
                                        sepa_debit: ibanElement,
                                        billing_details: {
                                            name: `${formData.firstName} ${formData.lastName}`,
                                            email: formData.email,
                                        },

                                    },
                                })
                                .then(function (result) {
                                    if (result.error) {
                                        navigate('/checkout/error/')
                                    } else {
                                        navigate('/checkout/success/')
                                    }
                                });


                            break;

                        case 'paypal':
                            const url = response.data.url;
                            window.location.href = url;
                            break;
                    }

                })
                .catch(function (error) {
                    console.log(error);
                });


        }

    }));


    return (
        <div className={styles.paymentTypes}>
            <div onClick={() => {
                changePaymentTypeHandler("paypal")
            }}>
                <Paypal
                    handleChange={handleChange}
                    paymentData={paymentData}
                    active={paymentType == "paypal" ? true : false}
                    hasError={hasError}
                />
            </div>
            <div onClick={() => {
                changePaymentTypeHandler("card")
            }}>
                <Card
                    handleChange={handleChange}
                    paymentData={paymentData}
                    active={paymentType == "card" ? true : false}
                    hasError={hasError}
                />
            </div>
            <div onClick={() => {
                changePaymentTypeHandler("sepa")
            }}>
                <Sepa
                    handleChange={handleChange}
                    paymentData={paymentData}
                    active={paymentType == "sepa" ? true : false}
                    hasError={hasError}
                />
            </div>
            <div onClick={() => {
                changePaymentTypeHandler("sofort")
            }}>
                <Sofort
                    handleChange={handleChange}
                    paymentData={paymentData}
                    active={paymentType == "sofort" ? true : false}
                    hasError={hasError}
                />
            </div>
        { hasError && <p className={formStyles.inputError}>Bitte fülle alle Felder korrekt aus.</p> }
        </div>

    )
})

export default PaymentTypes
