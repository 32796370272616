// extracted by mini-css-extract-plugin
export var checkoutPage = "checkoutPage-module--checkoutPage--19Rte";
export var white = "checkoutPage-module--white--3Gt30";
export var logoContainer = "checkoutPage-module--logoContainer--15p_9";
export var container = "checkoutPage-module--container--2RXUO";
export var logo = "checkoutPage-module--logo--1GoVL";
export var col = "checkoutPage-module--col--bU_Ps";
export var checkoutDetails = "checkoutPage-module--checkoutDetails--1vDYB";
export var title = "checkoutPage-module--title--11Mg2";
export var items = "checkoutPage-module--items--2i7Cc";
export var info = "checkoutPage-module--info--1qsnY";
export var orderCost = "checkoutPage-module--orderCost--4pW0A";
export var row = "checkoutPage-module--row--OBzkc";
export var total = "checkoutPage-module--total--2sW9i";
export var stages = "checkoutPage-module--stages--3Cryq";
export var stage = "checkoutPage-module--stage--2-CD6";
export var active = "checkoutPage-module--active--3J8Am";