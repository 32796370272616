import React from "react";
import { Fragment } from "react";
import './styles/loadingSpinnerStyles.css';

const LoadingSpinner = ({ style, white, containerHeight }) => {
    return (
        <div style={{ height: containerHeight }}>
            <div style={style} className={"lds-dual-ring-center-horizontal" + (white ? ' white' : '')}>
                <div className="lds-dual-ring-center-vertical">
                    <div className="lds-dual-ring"></div>
                </div>
            </div>
        </div>
    )

}

export default LoadingSpinner;

