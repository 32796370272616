import React from "react"
import { useForm } from "react-hook-form";

import * as formStyles from "../../scss/form.module.scss"
import * as btnStyles from "../buttons/buttons.module.scss"
import * as styles from "./checkout.module.scss"
import { changeCheckoutStep, storeFormDataAction } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "@reach/router";
import { withPrefix } from "gatsby";

const SecondStep = () => {
    const dispatch = useDispatch()
    const formData = useSelector(state => state.checkout.formData)
    const { register, handleSubmit, watch, errors } = useForm()
    dispatch(changeCheckoutStep({ currentStep: 2 }))
    const onChange = e => {
        dispatch(storeFormDataAction({ [e.target.name]: e.target.value }))
    }
    const onSubmit = data => {
        dispatch(storeFormDataAction(data))
        navigate(withPrefix("/checkout/3"));
    }


    return (
        <>
            <form className={formStyles.form} onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.title} style={{ marginBottom: "20px" }}>
                    Information
                </div>
                <div className={formStyles.twoItemsGroup} style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <div className={formStyles.inputGroup}>
                        <input
                            type="text"
                            className={`${`${formStyles.input}`} `}
                            onChange={onChange}
                            value={formData.firstName}
                            placeholder="Vorname"
                            name="firstName"
                            ref={register({
                                required: true,
                            })}
                        />
                    </div>
                    <div className={formStyles.inputGroup}>
                        <input
                            type="text"
                            className={`${`${formStyles.input}`} `}
                            placeholder="Nachname"
                            onChange={onChange}
                            value={formData.secondName}
                            name="secondName"
                            ref={register({
                                required: true,
                            })}
                        />
                    </div>
                </div>
                {errors.firstName && <p className={formStyles.inputError}>Vorname ist erforderlich</p>}
                {errors.secondName && <p className={formStyles.inputError}>Der zweite Name ist erforderlich</p>}
                <div className={formStyles.inputGroup}>

                    <input
                        type="text"
                        className={`${formStyles.input}`}
                        style={{ marginTop: "10px", marginBottom: "10px" }}
                        name="address"
                        onChange={onChange}
                        value={formData.address}
                        placeholder="Straße, Hausnummer"
                        ref={register({
                            required: true,
                        })}
                    />

                </div>
                {errors.address && <p className={formStyles.inputError}>Adresse ist erforderlich</p>}
                <div className={formStyles.twoItemsGroup} style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <div className={formStyles.inputGroup}>
                        <input
                            type="text"
                            className={`${formStyles.input}`}
                            name="city"
                            onChange={onChange}
                            value={formData.city}
                            placeholder="Stadt"
                            ref={register({
                                required: true,
                            })}
                        />
                    </div>
                    <div className={formStyles.inputGroup}>
                        <input
                            type="text"
                            className={`${formStyles.input}`}
                            name="postcode"
                            onChange={onChange}
                            value={formData.postcode}
                            placeholder="PLZ"
                            ref={register({
                                required: true,
                            })}
                        />
                    </div>
                </div>
                {errors.city && <p className={formStyles.inputError}>Stadt ist erforderlich</p>}
                {errors.postcode && <p class={formStyles.inputError}>PLZ ist erforderlich</p>}
                <input
                    type="text"
                    className={`${formStyles.input}`}
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                    name="phone"
                    onChange={onChange}
                    value={formData.phone}
                    placeholder="Telefon"
                    ref={register({
                        required: true,
                    })}
                />
                {errors.phone && <p className={formStyles.inputError}>Telefon ist erforderlich</p>}
                <button className={`${btnStyles.button} ${btnStyles.buttonBorder}`} style={{ marginTop: "10px" }}>
                    Weiter zur Zahlung
                </button>
            </form>
        </>
    )
}

export default SecondStep
