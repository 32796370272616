import React, { useState,Fragment } from "react"
import Link from "gatsby-link"
import { useForm } from "react-hook-form"
import SEO from "../components/seo"


import "../components/layout/layout.scss"
import * as styles from "../scss/checkoutPage.module.scss"


import Logo from "../images/logo.svg"
import Checkout from "../components/checkout"
import { useSelector } from "react-redux"

const CheckoutPage = () => {

    const currentStep = useSelector(state => state.checkout.currentStep)


    const { register, handleSubmit, watch, errors } = useForm()
    const onSubmit = data => {

    }

    return (
        currentStep < 5 ?
        <Fragment>
            <SEO
            title="Kassa"
            description = "Dein 7-Tage Detox Kurs - Alles was du brauchst, um optimal zu entgiften! Mit über 45 Videoanleitungen in HD, 7 strukturierten Tagesplänen, einem Detox-Rezeptbuch und alle notwendigen Unterlagen für eine erfolgreiche Detox-Woche."
            keywords="Detox, Abnehmen, Fasten, Online, Kurs, Entgiften,Entschlacken"
            author= "spectory"
            />
            <div className={`${styles.checkoutPage} ${currentStep < 4 ? '' : styles.white}`}>
                <div className={`container ${styles.container}`}>

                    {currentStep < 4 ?
                        <div className={styles.col}>
                            <Link to="/" className={styles.logoContainer}>
                                <Logo className={styles.logo} />
                            </Link>
                            <div className={styles.checkoutDetails}>
                                <div className={styles.items}>
                                    <img
                                        src="https://detoxme.at/wp/wp-content/uploads/2021/06/Ubersicht-Modul-5.5-Getranke-1-scaled.jpg"
                                        alt=""
                                    />
                                    <img
                                        src="https://detoxme.at/wp/wp-content/uploads/2021/06/shutterstock_1568585161-scaled-e1624969866432.jpg"
                                        alt=""
                                    />
                                    <img
                                        src="https://detoxme.at/wp/wp-content/uploads/2021/06/Ubersicht-Modul-2.1-Darum-brauchst-du-eine-Entgiftungskur-1-scaled-e1624971207419.jpg"
                                        alt=""
                                    />
                                    <img
                                        src="https://detoxme.at/wp/wp-content/uploads/2021/06/shutterstock_617837774-scaled-e1624969605409.jpg"
                                        alt=""
                                    />
                                </div>
                                <div className={styles.info}>
                                    <div className={styles.title}>
                                        Dein 7-Tage Detox Kurs
                                        <p className={styles.subheadline}>
                                            Alles was du brauchst, um optimal zu entgiften. 
                                        </p>
                                    </div>

                                    <ul style={{marginLeft:20}}>
                                        <li> Über 45 Videoanleitungen in HD </li>
                                        <li> 7 strukturierte Tagespläne </li>
                                        <li> Mein Detox-Rezeptbuch mit leckeren Gerichten</li>
                                        <li> PDF’s und Checklisten mit allen Informationen</li>
                                        <li> Tägliche Übungen zum emotionalen Detox</li>
                                    </ul>


                                    <div className={styles.orderCost}>
                                        <div className={`${styles.row} ${styles.total}`}>
                                            <div className={styles.col}>Summe:</div>
                                            <div className={styles.col}>€ 395,99</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <Link to="/" className={styles.logoContainer}>
                            <Logo className={styles.logo} />
                        </Link>}
                    <div className={styles.col}>
                        <Checkout />
                    </div>
                </div>
            </div>
        </Fragment>
            :
        <Fragment>
            <SEO
            title=" "
            description = "Dein 7-Tage Detox Kurs - Alles was du brauchst, um optimal zu entgiften! Mit über 45 Videoanleitungen in HD, 7 strukturierten Tagesplänen, einem Detox-Rezeptbuch und alle notwendigen Unterlagen für eine erfolgreiche Detox-Woche."
            keywords="Detox, Abnehmen, Fasten, Online, Kurs, Entgiften,Entschlacken"
            author= "spectory"
            />
            <Checkout/>
        </Fragment>

    )
}

export default CheckoutPage
