import * as React from "react"

import Layout from "../layout"
import SEO from "../seo"
import { useDispatch, useSelector } from "react-redux";
import { changeCheckoutStep, storeFormDataAction } from "../../store/actions";

import { useEffect, useRef, useState } from "react"

import Link from "gatsby-link";


import * as styles from "../../scss/privacy.module.scss"
import * as btnStyles from "../buttons/buttons.module.scss"


const Error = () => {
    const shapeFirst = useRef(null)
    const shapeSecond = useRef(null)
    const shapeThird = useRef(null)

    const dispatch = useDispatch()

    dispatch(changeCheckoutStep({ currentStep: 5 }))


    useEffect(() => {

        const shapeFistOffsetTop = shapeFirst.current.offsetTop

        const shapeSecondOffsetTop = shapeSecond.current.offsetTop

        window.addEventListener("scroll", () => {
            if (shapeFirst.current) {
                shapeFirst.current.style.top =
                    shapeFistOffsetTop + window.scrollY / 3 + "px"
                shapeSecond.current.style.top =
                    shapeSecondOffsetTop - window.scrollY / 3 + "px"
            } else {
                window.removeEventListener("scroll", () => { })
            }
        })
    })


    return (
        <Layout>
            <SEO title="Kauf Abgeschlossen" />
            <div className={styles.responsiveWrapper}>
                <div className={styles.hero}>
                    <div className={`clearfix container ${styles.container}`} style={{ margin: 0, padding: 0 }}>
                        <div className={styles.intro}>
                            <h1>
                                Kauf wurde abgebrochen.
                            </h1>
                            <p>
                                Der Kauf wurde leider abgebrochen.
                            </p>
                            <Link
                                to={'/'}
                                className={`${btnStyles.button} ${btnStyles.buttonBorder}`}
                            >
                                Zurück zur Startseite
                            </Link>

                        </div>
                        <div ref={shapeFirst} className={styles.shapeFirst}></div>
                        <div ref={shapeSecond} className={styles.shapeSecond}></div>
                        <div ref={shapeThird} className={styles.shapeThird}></div>
                    </div>
                </div>

                <div style={{ height: 40, backgroundColor: "#E4F8EF", width: "100%", position: "absolute", bottom: 0, left: 0, display: "block" }}></div>
            </div>
        </Layout>
    )
}

export default Error