import React, { useEffect, useState } from "react"
import { navigate } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form"

import { changeCheckoutStep, storeFormDataAction } from "../../store/actions";

import * as formStyles from "../../scss/form.module.scss"
import * as btnStyles from "../buttons/buttons.module.scss"
import * as styles from "./checkout.module.scss"
import { BsEye, BsEyeSlash } from "react-icons/all";
import { withPrefix } from "gatsby";

import LoadingSpinner from '../loadingSpinner';
import axios from "axios";


const Waiting = (props) => {
    const dispatch = useDispatch()

    dispatch(changeCheckoutStep({ currentStep: 4 }));

    const getPaypalStatus = (token) => {

        console.log('GET PAYPAL STATUS FOR ID', token);

        const isBrowser = typeof window !== "undefined";
        let userToken = null;

        if (isBrowser) {
            userToken = localStorage.getItem('user');
        }

        let data = JSON.stringify({
            "paypal_token": token
        });

        var config = {
            method: 'post',
            url: 'https://detoxme.at/wp/wp-json/siegfried/v1/shop/order/paypal/status',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log('GET PAYPAL STATUS', response);

                if (response.data.status === 'COMPLETED') {
                    navigate(withPrefix('/checkout/success'));
                } else if (response.data.status === 'APPROVED') {

                    setTimeout(() => getPaypalStatus(token), 3000);

                } else {
                    navigate(withPrefix('/checkout/error'));
                }

            })
            .catch(function (error) {
                console.log(error);
            });



    }

    useEffect(() => {

        let searchParams = new URLSearchParams(window.location.search)

        // CHECK IS STRIPE
        if (searchParams.get('redirect_status') !== null) {
            let status = searchParams.get('redirect_status');
            if (status === 'succeeded') {
                navigate(withPrefix('/checkout/success'));
            } else if (status === 'failed') {
                navigate(withPrefix('/checkout/error'));
            }
        } else if (searchParams.get('token') !== null) { // CHECK IS PAYPAL
            let paypalToken = searchParams.get('token');

            getPaypalStatus(paypalToken)

        }




    }, [])




    return (<>
        <LoadingSpinner />
        <div className="container" style={{ textAlign: 'center', marginTop: 50 }}>
            Wir brauchen einige Sekunden, um deine Zahlung zu bearbeiten.
        </div>
    </>
    )
}

export default Waiting
