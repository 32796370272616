import React, { Fragment, useEffect, useState } from "react"
import { navigate } from "@reach/router";
import { navigate as nav } from 'gatsby';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form"

import { changeCheckoutStep, storeFormDataAction } from "../../store/actions";

import * as formStyles from "../../scss/form.module.scss"
import * as btnStyles from "../buttons/buttons.module.scss"
import * as styles from "./checkout.module.scss"
import { BsEye, BsEyeSlash } from "react-icons/all";
import { withPrefix } from "gatsby";
import isLoggedIn from "../../services/auth";
import clearLocalStorage from "../../services/clearLocalStorage";
import { capiStartPurchase } from "../../services/capi";

const FirstStep = () => {
    const dispatch = useDispatch()
    const formData = useSelector(state => state.checkout.formData)
    const { register, handleSubmit, errors } = useForm()

    const [showPass, setShowPass] = useState(true);

    dispatch(changeCheckoutStep({ currentStep: 1 }))

    const onChange = e => {
        dispatch(storeFormDataAction({ [e.target.name]: e.target.value }))
    }

    const onSubmit = data => {
        navigate(withPrefix("/checkout/2"));
    }

    useEffect(() => {
        isLoggedIn().then(loggedIn => {
            if (loggedIn) navigate(withPrefix("/modules"));
            if (!loggedIn) {
                clearLocalStorage();

                capiStartPurchase();
            }

        });
    }, [])

    return (
        <Fragment>
            <form className={formStyles.form} onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.title} style={{ marginBottom: "20px" }}>
                    Starte deinen Bestellvorgang! Konto erstellen:
                </div>
                <p style={{ marginBottom: " 5px" }}>E-Mail</p>
                <input
                    name="email"
                    className={formStyles.input}
                    onChange={onChange}
                    value={formData.email}
                    placeholder="mail@adresse.com"
                    ref={register({
                        required: true,
                        pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i,
                    })}
                />
                {errors.email &&
                    <p class={formStyles.inputError} style={{ marginTop: "5px" }}>E-Mailadresse wird benötigt</p>}
                <p style={{ marginTop: "5px", marginBottom: "5px" }}>Passwort</p>
                <div className={formStyles.passwordInput}>
                    <input
                        type={showPass ? "password" : "text"}
                        name="password"
                        className={formStyles.input}
                        onChange={onChange}
                        value={formData.password}
                        placeholder="************"
                        ref={register({
                            required: true,
                            pattern: /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/i,
                        })}
                    />
                    <button type="button" onClick={() => {
                        setShowPass(!showPass)
                    }}>
                        {showPass ? <BsEye /> : <BsEyeSlash />}
                    </button>
                </div>
                {errors.password && (
                    <p class={formStyles.inputError} style={{ marginTop: "5px", marginbottom: "5px" }}>
                        Passwort ist erforderlich und mindestens acht Zeichen, mindestens
                        ein Großbuchstabe, ein Kleinbuchstabe und eine Zahl
                    </p>
                )}
                <input
                    className={`${btnStyles.button} ${btnStyles.buttonBorder}`}
                    style={{ marginTop: "20px", marginBottom: 30 }}
                    type="submit"
                    value="Weiter"
                />
            </form>

            <div className={styles.title} style={{ marginBottom: "20px", marginTop: 20 }}>
                Du hast den Kurs bereits gekauft?
            </div>

            <button
                onClick={() => nav("/login/")}
                className={`${btnStyles.button} ${btnStyles.buttonBorder}`}>
                Jetzt einloggen
            </button>
        </Fragment>

    )
}

export default FirstStep
