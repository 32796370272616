// extracted by mini-css-extract-plugin
export var hero = "privacy-module--hero--hD4pD";
export var container = "privacy-module--container--3Rf7y";
export var intro = "privacy-module--intro--3Zs7U";
export var shapeFirst = "privacy-module--shape-first--3hquB";
export var shapeSecond = "privacy-module--shape-second--2XM80";
export var shapeThird = "privacy-module--shape-third--3ltLe";
export var responsiveWrapper = "privacy-module--responsiveWrapper--3-HSE";
export var play = "privacy-module--play--GpTkG";
export var cover = "privacy-module--cover--3fvK3";
export var imageWrapper = "privacy-module--imageWrapper--1ROAQ";