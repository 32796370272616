import React from "react"
import { useDispatch, useSelector } from "react-redux";
import { Router } from "@reach/router"

import { changeCheckoutStep } from "../../store/actions";

import FirstStep from "./step-1"
import SecondStep from "./step-2"
import ThirdStep from "./step-3"
import Waiting from "./waiting"
import Success from './success';
import Error from './error';

import * as styles from "../../scss/checkoutPage.module.scss";
import { withPrefix } from "gatsby-link";

const Checkout = () => {
    const currentStep = useSelector(state => state.checkout.currentStep);

    return (
        <div>
            {currentStep < 4 &&
                <div className={styles.stages}>
                    <div className={`${styles.stage} ${currentStep == 1 && styles.active}`}>Anmeldung</div>
                    <div className={`${styles.stage} ${currentStep == 2 && styles.active}`}>Information</div>
                    <div className={`${styles.stage} ${currentStep == 3 && styles.active}`}>Zahlung</div>
                </div>
            }
            <Router basepath={withPrefix("/checkout")}>
                <FirstStep path="/" />
                <SecondStep path="/2" />
                <ThirdStep path="/3" />
                <Waiting path="/pending" />
                <Waiting path="/waiting" />
                <Success path="/success" />
                <Error path="/error" />
            </Router>
        </div>
    )
    
}
export default Checkout